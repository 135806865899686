import { Switch } from 'antd';
import React, { createContext, FC, useContext } from 'react';
import {
  AllRoleKey,
  GuestRoleKey,
  PreHeaderRoleList,
  PreHeaderRoleListKey,
  RegisteredFriendRoleKey,
  RegisteredLoverRoleKey,
  RegisteredRoleKey,
} from './WebeatPreHeaderSectionEnum';

import Text from 'antd/lib/typography/Text';

import {
  IPreHeaderRole,
  WebeatPreHeaderRolesConfig,
} from './WebeatPreHeaderSectionsConfig';

interface WebeatPreHeaderSectionContextType {
  combos: {
    fake: string;
  };
}

const WebeatPreHeaderSectionContext =
  createContext<WebeatPreHeaderSectionContextType | null>(null);

export const useWebeatPreHeaderSectionContext = () => {
  const context = useContext(WebeatPreHeaderSectionContext);
  if (!context) {
    throw new Error(
      'useWebeatPreHeaderSectionContext debe ser usado dentro de un WebeatPreHeaderSectionProvider',
    );
  }
  return context;
};

const RenderMessageFields: FC<{
  fieldConfig: {
    key: string;
    label: string;
    subLabel?: string;
    type: string;
    placeholder: string;
    required?: boolean;
    maxLength?: number;
  };

  preHeaderId?: string;
}> = ({ fieldConfig, preHeaderId }) => {
  if (fieldConfig.type === 'text') {
    return (
      <div>
        <Text>{fieldConfig.label}</Text>
      </div>
    );
  }

  return null;
};

export const WebeatPreHeaderSectionFields: FC<{
  active: boolean;
  preHeaderId?: string;
  parentProps: any;
  role: IPreHeaderRole;
  handleChange?: (roleKey: PreHeaderRoleListKey, active: boolean) => void;
}> = ({ role, active, preHeaderId, handleChange }) => {
  const preHeaderSectionListItem = PreHeaderRoleList[role.customerRole];

  const [activeState, setActive] = React.useState(!!active);

  const handleChangeActive = (value: boolean) => {
    if (handleChange) {
      handleChange(role.customerRole, value);
    }
    setActive(value);
  };

  return (
    <WebeatPreHeaderSectionContext.Provider
      value={{
        combos: {
          fake: 'fake',
        },
      }}>
      <div className="webeat-preheader-role__panel--content">
        <div
          style={{
            display: 'flex',
            gap: 10,
            alignItems: 'center',
          }}>
          <Switch
            checked={activeState}
            onChange={(e) => handleChangeActive(e)}
            disabled={preHeaderSectionListItem.required}
          />
          <Text>Active</Text>
        </div>
        {role.customerRole === AllRoleKey &&
          WebeatPreHeaderRolesConfig[AllRoleKey].fields.map((fieldConfig) => {
            return (
              <div key={fieldConfig.key}>
                <RenderMessageFields
                  fieldConfig={fieldConfig}
                  preHeaderId={preHeaderId}
                />
              </div>
            );
          })}
        {role.customerRole === GuestRoleKey &&
          WebeatPreHeaderRolesConfig[GuestRoleKey].fields.map((fieldConfig) => {
            return (
              <div key={fieldConfig.key}>
                <RenderMessageFields
                  fieldConfig={fieldConfig}
                  preHeaderId={preHeaderId}
                />
              </div>
            );
          })}

        {role.customerRole === RegisteredRoleKey &&
          WebeatPreHeaderRolesConfig[RegisteredRoleKey].fields.map(
            (fieldConfig) => {
              return (
                <div key={fieldConfig.key}>
                  <RenderMessageFields
                    fieldConfig={fieldConfig}
                    preHeaderId={preHeaderId}
                  />
                </div>
              );
            },
          )}
        {role.customerRole === RegisteredLoverRoleKey &&
          WebeatPreHeaderRolesConfig[RegisteredLoverRoleKey].fields.map(
            (fieldConfig) => {
              return (
                <div key={fieldConfig.key}>
                  <RenderMessageFields
                    fieldConfig={fieldConfig}
                    preHeaderId={preHeaderId}
                  />
                </div>
              );
            },
          )}
        {role.customerRole === RegisteredFriendRoleKey &&
          WebeatPreHeaderRolesConfig[RegisteredFriendRoleKey].fields.map(
            (fieldConfig) => {
              return (
                <div key={fieldConfig.key}>
                  <RenderMessageFields
                    fieldConfig={fieldConfig}
                    preHeaderId={preHeaderId}
                  />
                </div>
              );
            },
          )}
      </div>
    </WebeatPreHeaderSectionContext.Provider>
  );
};
