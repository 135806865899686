import React, { FC, useState } from 'react';
import { Collapse } from 'antd';
import {
  AllRoleKey,
  GuestRoleKey,
  PreHeaderRoleListKey,
  RegisteredRoleKey,
} from './WebeatPreHeaderSectionEnum';
import { WebeatPreHeaderSectionFields } from './WebeatPreHeaderSectionFields';
import { IPreHeaderRole } from './WebeatPreHeaderSectionsConfig';
import './WebeatPreHeaderSections.css';

import { FakeRoles } from './fakeData/fakeData';

const { Panel } = Collapse;

export interface IWebeatPreHeaderSectionsValuesProps {
  roles?: IPreHeaderRole[];
  id: string;
}

export const WebeatPreHeaderRoles: FC<{
  values: IWebeatPreHeaderSectionsValuesProps;
  parentProps: any;
  field: { key: string; type: string };
  handleChangeField: (params: {
    type: string;
    id: string;
    value: any;
    format?: string;
  }) => Promise<void> | void;
}> = ({ values, parentProps, handleChangeField, field }) => {
  const [roles, setRoles] = useState<IPreHeaderRole[]>(
    values.roles || FakeRoles,
  );

  const handleChangeRole = (roleKey: PreHeaderRoleListKey, active: boolean) => {
    const updatedRoles = roles.map((role: IPreHeaderRole) => {
      if (role.customerRole === roleKey) {
        return {
          ...role,
          active,
        };
      }
      return role;
    });

    setRoles(updatedRoles);
    handleChangeField({
      type: field.type,
      id: field.key,
      value: updatedRoles,
    });
  };

  return (
    <div>
      <Collapse accordion>
        {roles.map((role: IPreHeaderRole) => {
          return (
            <Panel
              header={role.customerRole}
              key={role.id}
              className={`${
                role?.active
                  ? 'webeat-preheader-role__panel--active'
                  : 'webeat-preheader-role__panel--inactive'
              } ${
                role.customerRole === RegisteredRoleKey ||
                role.customerRole === GuestRoleKey
                  ? 'webeat-preheader-role__panel--registered'
                  : role.customerRole === AllRoleKey
                  ? ''
                  : 'webeat-preheader-role__panel--registered-custom'
              }
              `}>
              <WebeatPreHeaderSectionFields
                active={!!role?.active}
                preHeaderId={values.id}
                parentProps={parentProps}
                handleChange={handleChangeRole}
                role={role}
              />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};
