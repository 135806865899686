import { Button, Card, Col, Icon, Popover, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  UPLOAD_ACTION_VIDEO_MAIN,
  UPLOAD_ACTION_VIMEO_URL_MAIN,
  UPLOAD_ACTION_YOUTUBE_URL_MAIN,
} from '../shared';
import { isConPlatform, isPINPlatform } from '../utils';
import { UpdateRecordProps } from './Challenge';
import ChallengeConfig from './ChallengeConfig';
import ChallengeForm from './ChallengeForm';
import { IResource, IResourceType } from './ChallengeInterfaces';
import { IChallengeRenderListProps } from './ChallengeRender';
import { isConExperience } from './ChallengeUtils';
import ResourcesDragDrop from './Components/ResourcesDragDrop';
import UploadComponent from './Components/UploadComponent';
import { ChallengeTypeEnum, ResourceTypeEnum } from './Enums';
import {
  getVideoTypeSelectorById,
  validateCoverVideoUrl,
} from './Components/Resource.utils';

const {
  FAQ,
  QUIZ,
  VIDEO,
  HIDDEN,
  ACTIVATION_BUTTON,
  RESOURCE_GUIDE,
  SELL_OUT,
  SELL_IN,
  CERTIFIED_COURSE,
} = ResourceTypeEnum;

export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default function ChallengeBuilder(props: IChallengeRenderListProps) {
  const [challengeResources, setChallengeResources] = useState<IResource[]>([]);
  const [editName, setEditName] = useState(false);
  const [editDescription, setEditDescription] = useState(false);
  const [editDate, setEditDate] = useState(false);

  const {
    availableResources,
    combos,
    constructResources,
    createResourceFromTemplate,
    editingChallenge,
    editingResource,
    formHasChanged,
    getMultiSelectValues,
    getTableData,
    loadChallengeData,
    handleChangeField,
    handleResetChallenge,
    handleUpdateRecord,
    intl,
    isLoading,
    params,
    refreshChallengeData,
    selectedRow,
    setEditionChallenge,
    setFormStateFlag,
    setLoading,
    setSelectedRow,
    sortingResources,
    targetId,
    token,
    values,
    updateChallengeData,
  } = props;
  const { formatMessage } = intl;
  const { primaryKey } = params;
  const prevChallengeResource = usePrevious(challengeResources);
  const prevSelectedRow = usePrevious(selectedRow);

  const updateProps: UpdateRecordProps = {
    targetId,
    params,
    updateChallengeData,
    getTableData,
    loadChallengeData,
    setFormStateFlag,
    setSelectedRow,
    values,
  };

  const isExperience = isConExperience(
    selectedRow?.idChallengeType?.idChallengeType,
  );
  const isContest =
    selectedRow?.idChallengeType?.idChallengeType === ChallengeTypeEnum.CONTEST;

  const isExperienceProduct =
    selectedRow?.idChallengeType?.idChallengeType ===
    ChallengeTypeEnum.EXPERIENCE_PRODUCT;

  // ------- HOOKS HELPERS ------- //

  const resetChallengeResources = () => {
    setChallengeResources(constructResources(selectedRow.resourceList));
  };

  useEffect(() => {
    if (!selectedRow || isEmpty(selectedRow)) setChallengeResources([]);
    else {
      if (!prevChallengeResource || prevSelectedRow !== selectedRow)
        resetChallengeResources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, availableResources, challengeResources]);

  const handleEditComponent = (component: string, value: boolean) => {
    switch (component) {
      case 'date':
        setEditDate(value);
        if (value && editName) setEditName(!value);
        if (value && editDescription) setEditDescription(!value);
        break;
      case 'name':
        setEditName(value);
        if (value && editDate) setEditDate(!value);
        if (value && editDescription) setEditDescription(!value);
        break;
      case 'description':
        setEditDescription(value);
        if (value && editName) setEditName(!value);
        if (value && editDate) setEditDate(!value);
        break;
      default:
        break;
    }

    if ((value && !editingChallenge) || (!value && editingChallenge))
      setEditionChallenge(value);
  };

  const handleAddResource = async (resource: IResourceType) => {
    const order =
      selectedRow.resourceList !== undefined
        ? selectedRow.resourceList.length
        : 0;

    const _resource: any = await createResourceFromTemplate(
      resource.idResourceType,
      {
        idChallenge: selectedRow.idChallenge,
        order,
      },
    );

    if ('status' in _resource && _resource.status === 200 && _resource.data)
      refreshChallengeData();
  };

  // ------- LEFT COLUMN ------- //

  const getResourceIcon = (id: number) => {
    switch (id) {
      case ResourceTypeEnum.LEGAL_CHECK:
        return 'read';
      case ResourceTypeEnum.TEXT:
        return 'font-size';
      case ResourceTypeEnum.VIDEO:
        return 'video-camera';
      case ResourceTypeEnum.IMAGE:
        return 'file-image';
      case ResourceTypeEnum.QUIZ:
      case ResourceTypeEnum.QUIZ_DERMO:
      case ResourceTypeEnum.QUIZ_TYPEFORM:
        return 'unordered-list';
      case ResourceTypeEnum.FORMATION_CERTIFICATE:
      case ResourceTypeEnum.CLAUSE:
        return 'audit';
      case ResourceTypeEnum.ANSWER:
        return 'environment';
      case ResourceTypeEnum.EVENT:
        return 'calendar';
      case ResourceTypeEnum.NOTIFICATION:
        return 'bell';
      case ResourceTypeEnum.EXPERTS_GROUP:
      case ResourceTypeEnum.SPEAKER:
        return 'audio';
      case ResourceTypeEnum.GALLERY:
        return 'appstore';
      case ResourceTypeEnum.FAQ:
        return 'question';
      case ResourceTypeEnum.MATERIAL:
        return 'file-add';
      case ResourceTypeEnum.SURVEY:
      case ResourceTypeEnum.ARN_SURVEY:
        return 'profile';
      case ResourceTypeEnum.ACTIVATION_BUTTON:
      case ResourceTypeEnum.EXPERIENCE_ACTIVATION:
        return 'play-circle';
      case ResourceTypeEnum.SHOPPING_CART:
      case ResourceTypeEnum.REDEEM_PRODUCT:
      case ResourceTypeEnum.SELL_OUT:
        return 'shopping-cart';
      case ResourceTypeEnum.SELL_IN:
        return 'shop';
      case ResourceTypeEnum.CHALLENGE_MATERIALS:
        return 'download';
      case ResourceTypeEnum.LINKED_CHALLENGES:
        return 'star';
      case ResourceTypeEnum.RESOURCE_GUIDE:
        return 'deployment-unit';
      case ResourceTypeEnum.EXPERIENCE_PROGRESS:
        return 'pull-request';
      case ResourceTypeEnum.CHALLENGE_VISIBILITY:
        return 'cloud-upload';
      case ResourceTypeEnum.CHECKOUT:
      case ResourceTypeEnum.CHECKOUT_LOTTERY:
      case ResourceTypeEnum.CHECKOUT_DONATION:
      case ResourceTypeEnum.CHECKOUT_PAYMENT_GATEWAY:
      case ResourceTypeEnum.CHECKOUT_SUPPLEMENTARY_PRODUCT:
        return 'dropbox';
      case ResourceTypeEnum.CHECKOUT_BOOKING_TIMIFY:
        return 'calendar';
      case ResourceTypeEnum.STEPS:
        return 'ellipsis';
      case ResourceTypeEnum.WYSIWYG:
        return 'pic-left';
      case ResourceTypeEnum.TEXT_DURATION:
        return 'clock-circle';
      case ResourceTypeEnum.TEXT_LINK:
        return 'link';
      case ResourceTypeEnum.VIDEO_SECTION:
        return 'youtube';
      case ResourceTypeEnum.GENERIC_BUTTON:
        return 'pic-center';
      case ResourceTypeEnum.WYSWYG_PIN:
        return 'pic-left';
      case ResourceTypeEnum.INCLUDED_ITEMS_LIST:
        return 'shopping';
      case ResourceTypeEnum.BEAUTY_GUIDE:
      case ResourceTypeEnum.HIDDEN:
        return 'qrcode';
      case ResourceTypeEnum.EXTERNAL_REDEEM_POINTS:
      case CERTIFIED_COURSE:
        return 'book';
      default:
        return 'question';
    }
  };

  const buttonDisabled =
    editName || editDescription || editDate || editingResource;

  const checkForDuplicate = (duplicatedID: number) => {
    let control = false;
    challengeResources.forEach((_resource) => {
      if (
        _resource.idResourceType.idResourceType === duplicatedID &&
        _resource.status
      )
        control = true;
    });
    return control;
  };

  const resourceSelector =
    editingResource || formHasChanged || sortingResources ? (
      <Button disabled type="primary" size="small" className="buttonNoFrame">
        {formatMessage({ id: 'challenge.addResource' })}
      </Button>
    ) : (
      <Popover
        placement={isEmpty(challengeResources) ? 'top' : 'bottom'}
        content={
          <Row className="CardPopover__container" type="flex" gutter={[16, 16]}>
            {!isEmpty(selectedRow) &&
              selectedRow.idChallengeType.resourceTypeList
                .filter(({ status }: IResourceType) => status)
                .sort(
                  (
                    { name }: IResourceType,
                    { name: nameToCompare }: IResourceType,
                  ) => {
                    return name.localeCompare(nameToCompare, 'en-US');
                  },
                )
                .map((resource: IResourceType, i: number) => {
                  let disabled = false;
                  switch (resource.idResourceType) {
                    case VIDEO:
                      if (isPINPlatform() || (isConPlatform() && isExperience))
                        break;
                      disabled = checkForDuplicate(resource.idResourceType);

                      break;
                    case QUIZ:
                    case FAQ:
                    case HIDDEN:
                    case ACTIVATION_BUTTON:
                    case RESOURCE_GUIDE:
                    case SELL_IN:
                    case SELL_OUT:
                    case CERTIFIED_COURSE:
                      if (!isExperience)
                        disabled = checkForDuplicate(resource.idResourceType);
                      break;
                  }

                  return (
                    <Col span={6} key={i}>
                      <Card
                        onClick={() =>
                          disabled ? null : handleAddResource(resource)
                        }
                        className={
                          disabled
                            ? 'CardPopover__cardDisabled'
                            : 'CardPopover__card'
                        }
                        key={i}
                        cover={
                          <Icon
                            type={getResourceIcon(resource.idResourceType)}
                          />
                        }
                      >
                        <Card.Meta
                          title={intl.formatMessage({
                            id: `component-type.${
                              ResourceTypeEnum[
                                resource.idResourceType
                              ]?.toLowerCase() || resource.name.toLowerCase()
                            }`,
                          })}
                        />
                      </Card>
                    </Col>
                  );
                })}
          </Row>
        }
      >
        <Button type="primary" size="small" className="buttonNoFrame">
          {formatMessage({ id: 'challenge.addResource' })}
        </Button>
      </Popover>
    );

  const leftColumn = (
    <>
      <ChallengeForm
        {...{
          buttonDisabled,
          constructResources,
          editingResource,
          formHasChanged,
          handleChangeField,
          handleEditComponent,
          handleResetChallenge,
          handleUpdateRecord,
          intl,
          isLoading,
          selectedRow,
          setLoading,
          sortingResources,
          updateProps,
          values,
          refreshChallengeData,
          challengeCategories:
            combos.challengechallengeEdit?.category?.challengeCategories.data,
        }}
      />
      <ResourcesDragDrop
        {...{
          challengeResources,
          primaryKey,
          resourceSelector,
          refreshChallengeData,
          resetChallengeResources,
          setChallengeResources,
        }}
      />
    </>
  );

  // ------- RIGHT COLUMN ------- //

  const UploadImage = (key: string) => {
    const handleSetCoverImage = (value?: any) => {
      handleChangeField({ id: key, value, type: 'text' });
    };

    return (
      <Row>
        <UploadComponent
          {...{
            disabled: editingResource,
            setValue: handleSetCoverImage,
            token,
            value: values?.[key] ?? '',
            handleChangeField,
            fieldName: key,
          }}
        >
          <Row className="media">
            <Button
              className="mediaButton"
              disabled={editingResource}
              size="small"
            >
              {intl.formatMessage({ id: `challenge.featured.${key}` })}
            </Button>
          </Row>
        </UploadComponent>
      </Row>
    );
  };
  const UploadVideo = (key: string) => {
    const handleSetCoverVideo = (value?: any) => {
      const videoType = getVideoTypeSelectorById('coverVideo');

      handleChangeField({
        id: key,
        value: value ? validateCoverVideoUrl(value, videoType).value : '',
        type: 'text',
      });
    };

    const handleSetIsVerticalVideo = (value: boolean) => {
      handleChangeField({
        id: key + 'IsVertical',
        value: value,
        type: 'boolean',
      });
    };
    return (
      <Row>
        <Row
          className="dateComponent dateComponent__title"
          style={{ color: 'rgba(0, 0, 0, 0.65)' }}
        >
          {intl.formatMessage({ id: `challenge.featured.${key}` })}
        </Row>
        <UploadComponent
          {...{
            disabled: editingResource,
            format: 'video',
            uploadActions: [
              UPLOAD_ACTION_VIDEO_MAIN,
              UPLOAD_ACTION_YOUTUBE_URL_MAIN,
              UPLOAD_ACTION_VIMEO_URL_MAIN,
            ],
            showIsVerticalVideo: false,
            setIsVerticalVideo: handleSetIsVerticalVideo,
            setValue: handleSetCoverVideo,
            token,
            isVerticalVideo: values?.[key + 'IsVertical'] ?? false,
            value: values?.[key] ?? '',
            handleChangeField,
            fieldName: key,
          }}
        >
          <Row className="media">
            <Button
              className="mediaButton"
              disabled={editingResource}
              size="small"
            >
              {intl.formatMessage({
                id: 'challenge.featured.coverVideo-upload',
              })}
            </Button>
          </Row>
        </UploadComponent>
      </Row>
    );
  };

  // TODO: API pass specialities combo and get info with challenge.tsx [redux] -- pass the specialitiesCombo to challengeConfig component
  const rightColumn = (
    <React.Fragment>
      <Row className="ChallengeBuilder__rightCol--content">
        <ChallengeConfig
          {...{
            contentTypeCombo:
              combos.challengechallengeEdit?.challengeContentType
                ?.challengeContentType?.data || [],
            roleTypeCombo:
              combos.challengechallengeEdit?.roleType?.roleType?.data || [],
            challengeTagCombo:
              (isContest
                ? combos.challengechallengeEdit?.contestTag?.contestTag?.data
                : combos.challengechallengeEdit?.experienceTag?.experienceTag
                    ?.data) || [],
            specialtyCombo:
              combos.challengechallengeEdit?.specialty?.specialty?.data || [],
            brandCombo:
              combos.challengechallengeEdit?.brand?.bobrands?.data || [],
            categoriesCombo:
              combos.challengechallengeEdit?.categories?.categories?.data,
            filtersCombo:
              combos.challengechallengeEdit?.filters?.boChallengeFilters?.data,
            filterTypeCombo:
              combos.challengechallengeEdit?.filterType?.filterType?.data,
            editingResource,
            formHasChanged,
            handleChangeField,
            handleEditComponent,
            intl,
            getMultiSelectValues,
            UploadImage,
            UploadVideo,
            values,
            isAnnualCampaign:
              selectedRow.idChallengeType.idChallengeType ===
              ChallengeTypeEnum.ANNUAL_CAMPAIGN,
            isExperience: isConExperience(
              selectedRow.idChallengeType.idChallengeType,
            ),
            isExperienceProduct: isExperienceProduct,
          }}
        />
      </Row>
    </React.Fragment>
  );

  return values ? (
    <Row
      className="ChallengeBuilder"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <Col
        xs={24}
        md={24}
        lg={18}
        xxl={19}
        className="ChallengeBuilder__leftCol"
      >
        {leftColumn}
      </Col>
      <Col
        xs={24}
        md={24}
        lg={6}
        xxl={5}
        className="ChallengeBuilder__rightCol"
      >
        {rightColumn}
      </Col>
    </Row>
  ) : (
    <React.Fragment />
  );
}
