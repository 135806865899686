export enum WebeatPreHeaderRoleType {
  All = 'All',
  Guest = 'Guest',
  Registered = 'Registered',
  RegisteredLover = 'RegisteredLover',
  RegisteredFriend = 'RegisteredFriend',
}

export const AllRoleKey = 'All';
export const GuestRoleKey = 'Guest';
export const RegisteredRoleKey = 'Registered';
export const RegisteredLoverRoleKey = 'RegisteredLover';
export const RegisteredFriendRoleKey = 'RegisteredFriend';

export type PreHeaderRoleListKey =
  | typeof AllRoleKey
  | typeof GuestRoleKey
  | typeof RegisteredRoleKey
  | typeof RegisteredLoverRoleKey
  | typeof RegisteredFriendRoleKey;

export const PreHeaderRoleList: Record<
  PreHeaderRoleListKey,
  { type: WebeatPreHeaderRoleType; required?: boolean }
> = {
  [AllRoleKey]: {
    type: WebeatPreHeaderRoleType.All,
    required: false,
  },
  [GuestRoleKey]: {
    type: WebeatPreHeaderRoleType.Guest,
    required: false,
  },
  [RegisteredRoleKey]: {
    type: WebeatPreHeaderRoleType.Registered,
    required: false,
  },
  [RegisteredLoverRoleKey]: {
    type: WebeatPreHeaderRoleType.RegisteredLover,
    required: false,
  },
  [RegisteredFriendRoleKey]: {
    type: WebeatPreHeaderRoleType.RegisteredFriend,
    required: false,
  },
};
