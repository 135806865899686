import { IPreHeaderRole } from '../WebeatPreHeaderSectionsConfig';

export const FakeRoles: IPreHeaderRole[] = [
  {
    id: '1',
    preHeaderId: '1',
    customerRole: 'All',
    active: false,
    messages: [
      {
        id: '1',
        preheader_role_id: '1',
        message: 'Hello',
        icon: 'icon',
        order: 1,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '1',
        message: 'Hello',
        icon: 'icon',
        order: 2,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '1',
        message: 'Hello',
        icon: 'icon',
        order: 3,
        active: false,
      },
    ],
  },
  {
    id: '2',
    preHeaderId: '1',
    customerRole: 'Guest',
    active: false,
    messages: [
      {
        id: '1',
        preheader_role_id: '2',
        message: 'Hello',
        icon: 'icon',
        order: 1,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '2',
        message: 'Hello',
        icon: 'icon',
        order: 2,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '2',
        message: 'Hello',
        icon: 'icon',
        order: 3,
        active: false,
      },
    ],
  },
  {
    id: '3',
    preHeaderId: '1',
    customerRole: 'Registered',
    active: false,
    messages: [
      {
        id: '1',
        preheader_role_id: '3',
        message: 'Hello',
        icon: 'icon',
        order: 1,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '3',
        message: 'Hello',
        icon: 'icon',
        order: 2,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '3',
        message: 'Hello',
        icon: 'icon',
        order: 3,
        active: false,
      },
    ],
  },
  {
    id: '4',
    preHeaderId: '1',
    customerRole: 'RegisteredLover',
    active: false,
    messages: [
      {
        id: '1',
        preheader_role_id: '4',
        message: 'Hello',
        icon: 'icon',
        order: 1,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '4',
        message: 'Hello',
        icon: 'icon',
        order: 2,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '4',
        message: 'Hello',
        icon: 'icon',
        order: 3,
        active: false,
      },
    ],
  },
  {
    id: '5',
    preHeaderId: '1',
    customerRole: 'RegisteredFriend',
    active: false,
    messages: [
      {
        id: '1',
        preheader_role_id: '5',
        message: 'Hello',
        icon: 'icon',
        order: 1,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '5',
        message: 'Hello',
        icon: 'icon',
        order: 2,
        active: false,
      },
      {
        id: '2',
        preheader_role_id: '5',
        message: 'Hello',
        icon: 'icon',
        order: 3,
        active: false,
      },
    ],
  },
];
