import { WebeatPreHeaderRoleType } from './WebeatPreHeaderSectionEnum';

export const WebeatPreHeaderRolesConfig: {
  [key in keyof typeof WebeatPreHeaderRoleType]: {
    fields: {
      key: string;
      label: string;
      subLabel?: string;
      type: string;
      placeholder: string;
      required?: boolean;
      maxLength?: number;
    }[];
  };
} = {
  [WebeatPreHeaderRoleType.All]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field PreHeader for All Users',
        type: 'text',
        placeholder: 'Enter All Users content',
        required: false,
      },
    ],
  },
  [WebeatPreHeaderRoleType.Guest]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field PreHeader for Guest Users',
        type: 'text',
        placeholder: 'Enter Guest Users content',
        required: false,
      },
    ],
  },
  [WebeatPreHeaderRoleType.Registered]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field PreHeader for Registered Users',
        type: 'text',
        placeholder: 'Enter Registered Users content',
        required: false,
      },
    ],
  },
  [WebeatPreHeaderRoleType.RegisteredLover]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field PreHeader for Lover Users',
        type: 'text',
        placeholder: 'Enter Lover Users content',
        required: false,
      },
    ],
  },
  [WebeatPreHeaderRoleType.RegisteredFriend]: {
    fields: [
      {
        key: 'example-field',
        label: 'Example Field PreHeader for Friend Users',
        type: 'text',
        placeholder: 'Enter Friend Users content',
        required: false,
      },
    ],
  },
};

export interface IPreHeaderRole {
  id: string;
  preHeaderId: string;
  customerRole: keyof typeof WebeatPreHeaderRoleType;
  active: boolean;
  messages?: any;
}
